<template>
  <router-view></router-view>
</template>

<script setup lang="ts">

</script>

<style lang="scss">

</style>
